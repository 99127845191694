// Libraries
import { useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'

export const useForm = (
  initialState,
  validate,
  submit,
  auth,
  history,
  submittionType
) => {
  const [values, setValues] = useState(initialState)
  const [errors, setErrors] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)

  useEffect(() => {
    function syncSubmitForm() {
      if (isEmpty(errors)) {
        submit(values, auth, history)
      }
      setSubmitting(false)
    }

    async function _asyncSubmitForm() {
      if (isEmpty(errors)) {
        await submit(values, auth, history)
        // setValues(initialState);
      }
      setSubmitting(false)
    }

    if (isSubmitting) {
      if (submittionType === 'async') {
        _asyncSubmitForm()
      } else {
        syncSubmitForm()
      }
    }
    // eslint-disable-next-line
  }, [isSubmitting])

  function handleChange(e, otherValues) {
    if (otherValues) {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
        ...otherValues,
      })
    } else {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      })
    }
  }

  function handleOnBlur() {
    // const validationErrors = validate(values)
    // setErrors(validationErrors)
  }

  function handleSubmit(e) {
    e.preventDefault()
    const validationErrors = validate(values)
    setErrors(validationErrors)
    setSubmitting(true)
  }

  return {
    values,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    handleOnBlur,
    setErrors,
  }
}
