import { useEffect } from 'react'

import { App } from '@capacitor/app'
import { useRouter } from '../../hooks/use-router'

export default function BackButtonListener() {
  const { history } = useRouter()

  useEffect(() => {
    const listener = () => {
      history.goBack()
    }
    App.addListener('backButton', listener)
  }, [history])

  return null
}
