import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { authContext } from '../../hooks/use-auth'

export default function PublicRoute({ component: Component, ...rest }) {
  return (
    <authContext.Consumer>
      {({ user }) => {
        return (
          <Route
            {...rest}
            render={(props) => {
              if (user) return <Redirect to="/" />
              return <Component {...props} />
            }}
          />
        )
      }}
    </authContext.Consumer>
  )
}
