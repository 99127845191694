import { fetch } from '../hooks/use-fetch';

export const loadBankAccounts = async (clientId, auth) => {
  return await fetch.get('/getBankAccs', {
    params: {
      clientId,
    }
  }, auth);
}

export const loadStructure = async (clientId, auth) => {
  return await fetch.get('/getReimbursementStructure', {
    params: {
      clientId,
    }
  }, auth);
}
export const loadAllClaims = async (clientId, auth, cardId) => {
  return await fetch.get('/claims', {
    params: {
      clientId,
      archiveData: 'true',
      // cardId
    }
  }, auth);
}

export const makeReimbursement = async (data, auth) => {
  return await fetch.post('/createClaim', {
    body: JSON.stringify(data)
  }, auth);
}