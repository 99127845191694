// Lib
import { Capacitor } from '@capacitor/core'

/** URL for deep links */
export const APP_ORIGIN = 'app://bulstrad.com'

/** URL for epay test env */
export const DEV_EPAY_API = 'https://bassist.tbiinfo.eu/payment-redirect'

/** Whether the app is running inside Capacitor */
export const isMobile = Capacitor.isNativePlatform() === true
