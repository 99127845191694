// IE 11 Polyfill
import { Capacitor } from '@capacitor/core'
import MomentUtils from '@date-io/moment'
// Mobile integration
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import 'moment/locale/bg'
import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { Provider } from 'react-redux'
// import ReactDOM from 'react-dom'
import { render } from 'react-snapshot'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import Index from './components/Main'
import { LANG_BG, LANG_LOCAL_STORAGE_KEY } from './config/languageTypes'
import { linkers } from './mobile'
import * as reducers from './reducers'
import { getPortalCurrentVersion, getPortalLatestVersion } from './versioning'

// Langugea
const currentLanguage = localStorage.getItem(LANG_LOCAL_STORAGE_KEY)
let locale = null

if (currentLanguage) {
  locale = moment.locale(currentLanguage)
  moment.locale(currentLanguage)
} else {
  // Set default language
  localStorage.setItem(LANG_LOCAL_STORAGE_KEY, LANG_BG)

  // Set moment default local
  locale = moment.locale(LANG_BG)
  moment.locale(LANG_BG)
}

const rootReducer = combineReducers(reducers)
/* eslint-disable no-underscore-dangle */
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
/* eslint-enable no-underscore-dangle */
const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)))

;(async () => {
  if (Capacitor.isNativePlatform()) {
    const { build_number: currentBuildVersion } =
      await getPortalCurrentVersion()

    linkers.portalVersion.next(currentBuildVersion)

    console.info('Setting portal version on init: ', currentBuildVersion)

    function checkPortalVersion() {
      setInterval(async () => {
        // Don't check for when the update dialog is present
        if (linkers.shouldUpdateApp.value) return

        console.info('Running version check...')
        const { build_number: latestBuildVersion } =
          await getPortalLatestVersion()

        console.info('Latest portal version: ', latestBuildVersion)
        console.info('Current app version: ', currentBuildVersion)

        if (latestBuildVersion > currentBuildVersion) {
          console.info('New portal version available!')
          linkers.shouldUpdateApp.next(true)
          return
        }

        console.info('No new portal version available.')
        linkers.shouldUpdateApp.next(false)
      }, 1000 * 60 * 5) // 5 minutes
    }

    checkPortalVersion()
  }
})()

render(
  <Provider store={store}>
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale={locale}
    >
      <Index />
    </MuiPickersUtilsProvider>
  </Provider>,
  document.getElementById('root')
)

// Mobile integration: Define custom elements after initial render / init
defineCustomElements(window)
