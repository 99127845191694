import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import HamburgerIcon from '@material-ui/icons/Menu'
import Person from '@material-ui/icons/Person'

import { analytics } from '../../../utils/firebase'
import { useAuth } from '../../../hooks/use-auth'
import { useRouter } from '../../../hooks/use-router'
import { useMobileVersion } from '../../../hooks/use-mobile-version'
import { isMobile as isCapacitor } from '../../../mobile'

import LoginButton from '../../LoginButton'
import MenuDrawer from '../MenuDrawer'
import MessageCount from '../MessageCount'
import Logo, { BAssistLogo, BulstradLogo } from '../Logo'
import CapacitorNavbar from '../CapacitorNavbar'

const useStyles = makeStyles(() => ({
  grid: {
    display: 'grid',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  threeColumns: {
    gridTemplateColumns: '20% 20% 20%',
  },
  twoColumns: {
    gridTemplateColumns: '30% 35%',
  },
  oneColumn: {
    gridTemplateColumns: '1fr',
  },
}))

export default function MobileNavbar({ navBarOptions }) {
  const [drawerState, setDrawerState] = useState(false)
  const classes = useStyles()
  const isMobile = useMobileVersion()
  const auth = useAuth()
  const { history } = useRouter()

  history.listen((location, action) => {
    setDrawerState(false)
  })

  const handleMessagesClick = (history) => {
    history.push('/messages')
    analytics.logEvent('messages')
  }

  return (
    <>
      <div className={`nav ${navBarOptions ? 'nav-border' : ''}`}>
        <div
          className={`${classes.grid} container ${
            auth.user
              ? classes.threeColumns
              : isCapacitor
              ? classes.oneColumn
              : classes.twoColumns
          }`}
        >
          {auth.user &&
            (isCapacitor ? (
              <IconButton
                color="secondary"
                aria-label="User information"
                onClick={() => {
                  history.push('/user')
                  analytics.logEvent('profile')
                }}
                size="medium"
              >
                <Person fontSize="large" />
              </IconButton>
            ) : (
              <IconButton
                color="secondary"
                onClick={() => setDrawerState(true)}
                aria-label="Navigation menu"
              >
                <HamburgerIcon fontSize={isMobile ? 'large' : 'default'} />
              </IconButton>
            ))}

          {!auth.user ? (
            isCapacitor ? (
              <CapacitorNavbar />
            ) : (
              <BulstradLogo />
            )
          ) : (
            <BAssistLogo />
          )}

          {auth.user && auth.claims && auth.claims.role === 'client' && (
            <IconButton
              onClick={() => handleMessagesClick(history)}
              size="medium"
            >
              <MessageCount />
            </IconButton>
          )}
          {!auth.user && !isCapacitor && <LoginButton />}
        </div>
      </div>

      <MenuDrawer open={drawerState} setOpen={setDrawerState} />
    </>
  )
}
