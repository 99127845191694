import React from 'react'
import { analytics } from '../../../utils/firebase'
import BtnLoginFacebook from '../../BtnLoginSocial/BtnLoginFacebook'
import BtnLoginGoogle from '../../BtnLoginSocial/BtnLoginGoogle'
import LoginForm from '../../Form/LoginForm'
import RegisterForm from '../../Form/RegisterForm'
import Dialog from '../../Dialog'
import ForgottenPassword from '../../Form/ForgottenPassword'
import styles from './auth-tabs.module.css'
import { useTranslation } from '../../../hooks/use-translation'
import BtnLoginApple from '../../BtnLoginSocial/BtnLoginApple'
import { canLoginWithApple } from '../../../mobile'
import { Capacitor } from '@capacitor/core'

// ! Should split this component and add forms for login + register

const TabContent = ({ currentTab, index, children }) =>
  currentTab === index ? <div>{children}</div> : null

const Divider = ({ text }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      justifyContent: 'center',
    }}
  >
    <div
      style={{
        backgroundColor: '#CDCBC3',
        position: 'absolute',
        left: 0,
        right: 0,
        height: '2px',
        zIndex: 1,
      }}
    ></div>
    <span
      style={{
        backgroundColor: '#fff',
        zIndex: 2,
        padding: '0 5px',
        color: '#CDCBC3',
      }}
    >
      {text}
    </span>
  </div>
)

const handleRegisterClick = (setTab) => {
  setTab(1)
  analytics.logEvent('vhod_register')
}

const handleForgotPasswordClick = (setTab) => {
  setTab(2)
  analytics.logEvent('vhod_forgot_password')
}

const AuthTabs = () => {
  const { translate } = useTranslation()
  const [tab, setTab] = React.useState(0)

  return (
    <Dialog style={{ zIndex: 1001 }}>
      <button
        className={`${styles['tab-button']} ${tab === 0 && styles.active}`}
        onClick={() => setTab(0)}
      >
        {translate('c-auth-dialog.tabs.login')}
      </button>
      <button
        className={`${styles['tab-button']} ${tab === 1 && styles.active}`}
        onClick={() => handleRegisterClick(setTab)}
      >
        {translate('c-auth-dialog.tabs.register')}
      </button>
      <button
        className={`${styles['tab-button']} ${tab === 2 && styles.active}`}
        onClick={() => handleForgotPasswordClick(setTab)}
      >
        {translate('c-auth-dialog.tabs.forgotten-pass')}
      </button>

      <TabContent currentTab={tab} index={0}>
        {/* social buttons */}
        <div className={styles['form-container']}>
          <div className={styles['facebook-container']}>
            <BtnLoginFacebook />
            <div className={styles['facebook-container']}>
              <BtnLoginGoogle />
            </div>
            {canLoginWithApple && (
              <div className={styles['facebook-container']}>
                <BtnLoginApple />
              </div>
            )}
          </div>
        </div>
        {/* ! social buttons */}

        <div className={styles['divider-container']}>
          <Divider text={translate('other.or')} />
        </div>

        <div>
          <LoginForm />
        </div>
      </TabContent>

      <TabContent currentTab={tab} index={1}>
        <div className={styles['form-container']}>
          <RegisterForm />
        </div>
      </TabContent>

      <TabContent currentTab={tab} index={2}>
        <div className={styles['form-container']}>
          <ForgottenPassword />
        </div>
      </TabContent>
    </Dialog>
  )
}

export default AuthTabs
