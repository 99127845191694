import React from 'react'
import BassistNotification from '../components/Notifications/BassistNotification'
import { fetch } from '../hooks/use-fetch'
import { mutate } from 'swr'
import { messaging } from '../utils/firebase'
import { translate } from '../utils/lang'
// Mobile integration
import { isMobile } from '../mobile'

export const updateDeviceToken = async (data, auth) => {
  return await fetch.post(
    '/changeDeviceId',
    {
      body: JSON.stringify(data),
    },
    auth
  )
}

export const sendToken = async ({ notifier }, auth) => {
  // This will break on native
  if (isMobile) return

  try {
    const token = await messaging.getToken({
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    })
    // send refreshed token to the server
    if (auth.claims.role === 'broker') {
      await updateDeviceToken(
        {
          deviceId: token,
          userId: auth.claims.userId,
          deviceOs: 'Web',
          isLogin: false,
        },
        auth
      )
    } else {
      await updateDeviceToken(
        {
          deviceId: token,
          clientId: auth.claims.clientId,
          deviceOs: 'Web',
          isLogin: false,
        },
        auth
      )
    }
  } catch (e) {
    notifier.enqueueSnackbar(translate('s-notification.error-init'), {
      variant: 'error',
    })
    console.error('Error getting FCM token', e)
  }
}

export const registerNotificationListener = ({ notifier, router }, auth) => {
  messaging.onMessage(
    (payload) => {
      // update message count
      mutate('/getMessageCount')

      notifier.enqueueSnackbar(payload.data.title, {
        content: (key, _) => (
          <BassistNotification
            style={{ background: 'red' }}
            id={key}
            notificationPayload={payload.data}
            message={payload.data.title}
            config={{ auth, router }}
          />
        ),
        autoHideDuration: 20000,
        dense: 'true',
      })
    },
    (error) => {
      console.log('error while receiving push notification: ', error.message)
    }
  )
}
