// Libraries
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
// Hooks
import { useAuth } from '../../../hooks/use-auth'
import { useMobileVersion } from '../../../hooks/use-mobile-version'
import { useTranslation } from '../../../hooks/use-translation'
// Config
import { LANG_BG, LANG_EN } from '../../../config/languageTypes'

const useStyles = makeStyles(() => ({
  mobileBulstradLogo: {
    width: '101px',
  },
  desktopBulstradLogo: {
    width: '135px',
  },
  mobileBAssistLogo: {
    width: '35px',
  },
  desktopBAssistLogo: {
    width: '129px',
  },
  textLeft: {
    textAlign: 'left',
  },
  textCenter: {
    textAlign: 'center',
  },
}))

export const BulstradLogo = () => {
  const classes = useStyles()
  const isMobile = useMobileVersion()
  const { activeLanguage } = useTranslation()

  let imageSrc = ''

  // We choose the right source according to the language
  if (activeLanguage === LANG_EN) {
    imageSrc = '/images/Bulstrad_Life_Logo_home_EN.svg'
  } else if (activeLanguage === LANG_BG) {
    imageSrc = '/images/Bulstrad_Life_Logo_home.svg'
  }

  return (
    <a
      href="https://www.bulstradlife.bg"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={imageSrc}
        className={
          isMobile ? classes.mobileBulstradLogo : classes.desktopBulstradLogo
        }
        alt="Bulstrad Life Logo"
      />
    </a>
  )
}

export const BAssistLogo = () => {
  const { user } = useAuth()
  const classes = useStyles()
  const isMobile = useMobileVersion()

  return (
    <Link
      to="/"
      className={`${
        user ? classes.textCenter : classes.textLeft
      } link-block-2 w-inline-block w--current`}
    >
      <img
        src={isMobile ? '/images/b-assist_heart_red.png' : '/images/logo.png'}
        alt="B-Assist Logo"
        className={
          isMobile ? classes.mobileBAssistLogo : classes.desktopBAssistLogo
        }
      />
    </Link>
  )
}

const Logo = () => {
  const { user, loading } = useAuth()
  const { location } = useHistory()

  if (loading && !user) {
    return <Skeleton animation="wave" width={130} height={55} />
  }

  return (
    <>
      {location.pathname === '/' && !user ? <BulstradLogo /> : <BAssistLogo />}
    </>
  )
}

export default Logo
