// Lib
import { useEffect, useState } from 'react'
import { Subject, BehaviorSubject } from 'rxjs'
import { App } from '@capacitor/app'
// Config
import { isMobile } from './config'
import { Capacitor } from '@capacitor/core'

// Export from config so other code can import from this file
export { isMobile } from './config'

export const canLoginWithApple =
  (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios') ||
  Capacitor.getPlatform() === 'web'

/**
 * Rx Subject linkers for passing data to the UI
 */
export const linkers = {
  deepLink: new Subject(),
  msgToken: new Subject(),
  pushNotification: new Subject(),
  pushNotificationAction: new Subject(),
  pushInitError: new Subject(),
  reset: new Subject(),
  shouldUpdateApp: new BehaviorSubject(false),
  portalVersion: new BehaviorSubject(null),
}

/** Mobile logic init flag */
let initDone = false

/** State change init flag */
// let activated = false

/**
 * Init capacitor logic. Will only run if the app is
 * rendered inside an iframe.
 */
export const init = () => {
  // This logic executes only once, on mobile
  if (!isMobile || initDone) return
  initDone = true
  // Listen for deep links
  App.addListener('appUrlOpen', (data) => {
    // Send to the appropriate Subject
    linkers.deepLink.next(data.url.split('app://bulstrad.com').pop())
  })
  // Listen for reinit (need to reset portal)
  // App.addListener('appStateChange', (state) => {
  //   if (state.isActive && !activated) window.location.reload()
  //   activated = true
  // })
}

/**
 * Receive a value from a capacitor event
 *
 * @param {"deepLink"
 * | "msgToken"
 * | "pushNotification"
 * | "pushNotificationAction"
 * | "shouldUpdateApp"
 * | "portalVersion"
 * | "pushInitError"} event
 */
export const useCapacitorEvents = (event) => {
  const [value, setValue] = useState(null)

  useEffect(
    () => {
      // Sub ref holder
      let sub
      // Subscribe
      sub = linkers[event].subscribe((v) => {
        // Set value
        setValue(v)
        // Then nullify to fix useEffect change tracking
        setValue(null)
      })
      // Cleanup
      return () => {
        sub.unsubscribe()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return value
}
