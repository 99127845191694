import { fetch } from '../hooks/use-fetch'

export const getMessages = (url, auth) => {
  return fetch.get(
    url,
    {
      params: {
        clientId: auth.claims.clientId
      }
    },
    auth
  )
}

export const updateMessageStatus = (url, data, auth) => {
  return fetch.post(
    url,
    {
      body: JSON.stringify(data)
    },
    auth
  )
}

export const getMessageCount = (url, auth) => {
  return fetch.get(
    url,
    {
      params: {
        clientId: auth.claims.clientId
      }
    },
    auth
  )
}
