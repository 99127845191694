export async function getPortalLatestVersion() {
  try {
    const headers = new Headers()
    headers.append('pragma', 'no-cache')
    headers.append('cache-control', 'no-cache')

    const response = await fetch(process.env.REACT_APP_VERSIONING_URL, {
      headers,
    })
    const data = await response.json()
    return data
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

export async function getPortalCurrentVersion() {
  try {
    const response = await fetch('/version.json')
    const data = await response.json()
    return data
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}
