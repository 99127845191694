import React, { Suspense } from 'react'
import { Badge } from '@material-ui/core'
import MessageIcon from '@material-ui/icons/Message'
import useSWR from 'swr'

import { getMessageCount } from '../../../services/messagesService'
import { useAuth } from '../../../hooks/use-auth'
import { isMobile as isCapacitor } from '../../../mobile'

const MessageCount = () => {
  const auth = useAuth()

  const { data, error } = useSWR(
    '/getMessageCount',
    (url) => getMessageCount(url, auth),
    { suspense: true, revalidateOnFocus: false }
  )

  return (
    <Suspense fallback={<div>loading...</div>}>
      <Badge
        badgeContent={!error && data.message_counter_not_read}
        color="primary"
      >
        <MessageIcon
          color="secondary"
          {...(isCapacitor && { fontSize: 'large' })}
        />
      </Badge>
    </Suspense>
  )
}

export default MessageCount
