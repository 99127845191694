import { Geolocation } from '@capacitor/geolocation'
import { Keyboard } from '@capacitor/keyboard'
import { createTheme, makeStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { SnackbarProvider } from 'notistack'
import React, { lazy, Suspense, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import '../../assets/css/custom-webflow.css'
import { authContext } from '../../hooks/use-auth'
import { ProvideAuth } from '../../hooks/use-auth.js'
import { FormStoreProvider } from '../../hooks/use-form-store'
import { ProvideMobileVersion } from '../../hooks/use-mobile-version'
import { ProvideTranslations } from '../../hooks/use-translation'
// Mobile integration
import { init as mobileInit, isMobile as isCapacitor } from '../../mobile'
import BackButtonListener from '../../mobile/components/BackButtonListener'
import MsgTokenSenderNative from '../../mobile/components/MsgTokenSenderNative'
import UpdateAppListener from '../../mobile/components/UpdateAppListener'
import UrlListener from '../../mobile/components/UrlListener'
import About from '../../pages/About'
import Actions from '../../pages/Actions'
import PublicHome from '../../pages/Home/PublicHome'
import Login from '../../pages/Login'
import Register from '../../pages/Register'
import BotRedirect from '../BotRedirect'
import ErrorBoundary from '../ErrorBoundary'
import ErrorHandler from '../ErrorHandler'
import Footer from '../Footer'
import LoadingSpinner from '../LoadingSpinner'
import Navbar from '../Navbar'
import NotificationsNative from '../NotificationsNative/index'
import PublicRoute from '../PublicRoute'
import ScrollToTop from '../ScrollToTop'
import URLParamParser from '../URLParamParser'

// Lazy loads
const ThankYou = lazy(() => import('../../pages/ThankYou'))
const Logout = lazy(() => import('../../pages/Logout'))
const Chat = lazy(() => import('../Chat'))
const Client = lazy(() => import('./Client'))
const CookiesDialog = lazy(() => import('../CookiesDialog'))
const NewVersionDialog = lazy(() => import('../NewVersionDialog'))
// const GlobalMessageDialog = lazy(() => import('../GlobalMessageDialog'))
const Broker = lazy(() => import('./Broker'))
const BuyAccidentInsurance = lazy(() =>
  import('../../pages/BuyAccidentInsurance')
)
const BuyForeignersInsurance = lazy(() =>
  import('../../pages/BuyForeignersInsurance')
)
const BuyMultitripInsurance = lazy(() =>
  import('../../pages/BuyMultitripInsurance')
)
const BuyTravelInsurance = lazy(() => import('../../pages/BuyTravelInsurance'))
const PublicAppointment = lazy(() => import('../../pages/PublicAppointment'))
const Installments = lazy(() => import('../../pages/Installments'))

// Material UI Theme
const theme = createTheme({
  palette: {
    primary: { main: '#D42F42', grey: '#E1E1E1', white: '#FFFFFF' },
    secondary: { main: '#333' },
  },
  typography: {
    header3: {
      fontSize: '1.4em',
    },
    header5: {
      fontSize: '1.2rem',
    },
  },
})

const useStyles = makeStyles((theme) => ({
  root: { zIndex: 999999999, width: '70%' },
  success: { backgroundColor: '#D33244' },
  info: { backgroundColor: '#fefefe', color: '#333' },
  error: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #D33244',
    color: '#D33244',
  },
  warning: { backgroundColor: 'yellow' },
}))

const Index = () => {
  const classes = useStyles()

  useEffect(() => {
    if (isCapacitor) {
      Keyboard.setAccessoryBarVisible({ isVisible: true })

      async function checkLocationPermission() {
        const permissionStatus = await Geolocation.checkPermissions()

        if (permissionStatus.location === 'prompt') {
          await Geolocation.requestPermissions()
          return
        }

        if (permissionStatus.location !== 'granted') {
          console.error('Location permission denied')
          // throw new Error('User denied permissions!')
        }
      }

      checkLocationPermission()
    }
  }, [])

  return (
    <FormStoreProvider>
      <ThemeProvider theme={theme}>
        <ProvideMobileVersion>
          <ProvideTranslations>
            <SnackbarProvider
              autoHideDuration={10000}
              disableWindowBlurListener
              maxSnack={3}
              classes={{
                root: classes.root,
                variantSuccess: classes.success,
                variantError: classes.error,
                variantInfo: classes.info,
                variantWarning: classes.warning,
              }}
            >
              <UpdateAppListener />
              <ProvideAuth>
                <authContext.Consumer>
                  {({ claims, isUserLoading }) => {
                    if (isUserLoading) {
                      return <LoadingSpinner global />
                    }

                    // Signal capacitor
                    mobileInit()

                    return (
                      <Router>
                        <ScrollToTop />

                        {isCapacitor && (
                          <>
                            {/* Listen to native back button */}
                            <BackButtonListener />
                            {/* Listen to capacitor deep links */}
                            <UrlListener />
                            {/* Listen to native messaging tokens */}
                            <MsgTokenSenderNative />
                            {/* Listen to native notifications */}
                            <NotificationsNative />
                          </>
                        )}

                        <URLParamParser />

                        <Suspense fallback={''}>
                          <Navbar />
                        </Suspense>

                        <Switch>
                          <PublicRoute component={PublicHome} exact path="/" />
                          <PublicRoute component={About} exact path="/about" />
                          <PublicRoute component={Login} exact path="/login" />
                          <PublicRoute
                            component={Register}
                            exact
                            path="/register"
                          />
                          <PublicRoute
                            component={Actions}
                            exact
                            path="/actions"
                          />
                        </Switch>

                        <Suspense fallback={''}>
                          <Switch>
                            <Route
                              path="/buy-travel-insurance"
                              component={BuyTravelInsurance}
                            />
                            <Route
                              path="/buy-accident-insurance"
                              component={BuyAccidentInsurance}
                            />
                            <Route
                              path="/buy-multitrip-insurance"
                              component={BuyMultitripInsurance}
                            />
                            <Route
                              path="/buy-foreigners-insurance"
                              component={BuyForeignersInsurance}
                            />

                            <Route
                              exact
                              path="/installments/:type"
                              component={Installments}
                            />

                            <Route
                              path="/public/appointment/:appointmentId"
                              component={PublicAppointment}
                            />

                            <Route path="/thank-you" component={ThankYou} />
                            <Route path="/logout" component={Logout} />
                            <Route
                              path="/bot/credit-card-redirect"
                              render={() => (
                                <BotRedirect type="credit-card-redirect" />
                              )}
                            />
                            <Route
                              path="/bot/epay-redirect"
                              render={() => (
                                <BotRedirect type="epay-redirect" />
                              )}
                            />
                          </Switch>
                        </Suspense>

                        <Suspense fallback={<LoadingSpinner global />}>
                          <Switch>
                            <ErrorBoundary>
                              {claims && claims.role === 'client' && <Client />}
                              {claims && claims.role === 'broker' && <Broker />}
                            </ErrorBoundary>
                          </Switch>

                          <Chat />

                          {!isCapacitor && <CookiesDialog />}

                          {isCapacitor && <NewVersionDialog />}

                          <Footer />
                        </Suspense>
                      </Router>
                    )
                  }}
                </authContext.Consumer>
                <ErrorHandler />
              </ProvideAuth>
            </SnackbarProvider>
          </ProvideTranslations>
        </ProvideMobileVersion>
      </ThemeProvider>
    </FormStoreProvider>
  )
}

export default Index
