// Libraries
import { useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
// Hooks
import { useFormStore } from './use-form-store'

const usePersistentForm = (name, initialState = {}) => {
  const [formStore, setFormStore] = useFormStore()
  const form = formStore[name]

  useEffect(() => {
    if (!form) {
      setFormStore((draftState) => {
        draftState[name] = {}
        draftState[name].values = initialState
        draftState[name].errors = {}
        draftState[name].isSubmitting = false
      })
    }
  }, [form, setFormStore, name, initialState])

  // update form values
  const update = (key, payload) => {
    setFormStore((draftState) => {
      draftState[name][key] = payload
    })
  }

  //   Handle change
  const handleChange = (e, otherValues) => {
    if (otherValues) {
      update('values', {
        ...form.values,
        [e.target.name]: e.target.value,
        ...otherValues,
      })
    } else {
      update('values', {
        ...form.values,
        [e.target.name]: e.target.value,
      })
    }
  }

  const validate = (validatorFn) => {
    const errors = validatorFn(form.values)
    const hasErrors = !isEmpty(errors)

    if (hasErrors) {
      update('errors', errors)
    } else {
      update('errors', {})
    }

    return hasErrors
  }

  return {
    values: (form && form.values) || {},
    errors: (form && form.errors) || {},
    isSubmitting: (form && form.isSubmitting) || false,
    handleChange,
    update,
    validate,
  }
}

export { usePersistentForm }
