import React, { Suspense, lazy, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Button, Container, Typography } from '@material-ui/core'
import ScrollMagic from 'scrollmagic'
import { motion, useAnimation } from 'framer-motion'
import { useHistory } from 'react-router-dom'

import BoxSkeleton from '../../../components/Carousel/BoxSkeleton'
import { useTranslation } from '../../../hooks/use-translation'
import { useMobileVersion } from '../../../hooks/use-mobile-version'
import setAuthDialog from '../../../actions/snackbar/setAuthDialog'
import { analytics } from '../../../utils/firebase'
import { isMobile as isCapacitor } from '../../../mobile'
import s from './s.module.css'
import { Box } from '@material-ui/core'
import InsuranceTooltip from '../../../components/InsuranceTooltip'
import { useState } from 'react'
import LoginButton from '../../../components/LoginButton'

const Carousel = lazy(() => import('../../../components/Carousel'))

export default function PublicHome(props) {
  return (
    <Suspense fallback={<BoxSkeleton />}>
      <Carousel />
      {isCapacitor ? (
        <CapacitorContent {...props} />
      ) : (
        <DesktopAndMobileContent {...props} />
      )}
    </Suspense>
  )
}

const CapacitorContent = (props) => {
  const { translate } = useTranslation()
  const history = useHistory()
  const [isInsuranceTooltipOpen, setIsInsuranceTooltipOpen] = useState(false)

  const insurances = [
    translate('life-insurance-types.life'),
    translate('life-insurance-types.child'),
    translate('life-insurance-types.unit-linked'),
    translate('life-insurance-types.term'),
  ]

  return (
    <Box
      p={3}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="stretch"
    >
      <Box mb={3} textAlign="center">
        <Typography display="inline">
          {translate(
            'c-client-dashboard.my-insurance.other-tab.installment-page.title'
          )}
        </Typography>{' '}
        <InsuranceTooltip
          insurances={insurances}
          isOpen={isInsuranceTooltipOpen}
          setIsOpen={setIsInsuranceTooltipOpen}
          buttonText={translate(
            'c-client-dashboard.my-insurance.other-tab.installment-page.title-link'
          )}
        />
      </Box>
      <Button
        color="primary"
        variant="outlined"
        size="large"
        onClick={() => {
          analytics.logEvent('header_menu_pay_online')
          history.push('/installments/regular-payment')
        }}
      >
        {translate('installments.header-title')}
      </Button>

      <Box mt={5} textAlign="center">
        <Typography>{translate('capacitor-home.pre-login-text')}</Typography>
      </Box>
      <Box mt={3}>
        <LoginButton />
      </Box>
    </Box>
  )
}

const DesktopAndMobileContent = (props) => {
  const { translate, activeLanguage } = useTranslation()
  const firstImageAnimationControls = useAnimation()
  const firstProductControl = useAnimation()
  const secondImageAnimationControls = useAnimation()
  const secondProductControl = useAnimation()
  const thirdImageAnimationControls = useAnimation()
  const thirdProductControl = useAnimation()
  const isMobile = useMobileVersion()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isMobile) {
      // Init ScrollMagic Controller
      const controller = new ScrollMagic.Controller() // Main scrollmagic controller for project

      // build scenes
      new ScrollMagic.Scene({
        triggerElement: '#firstProd',
        duration: 200,
        offset: -230,
      })
        .on('enter', () => {
          firstImageAnimationControls.start('visible')
          firstProductControl.start('visible')
        })
        .addTo(controller)

      new ScrollMagic.Scene({
        triggerElement: '#secondProd',
        duration: 200,
        offset: -300,
      })
        .on('enter', () => {
          secondImageAnimationControls.start('visible')
          secondProductControl.start('visible')
        })
        .addTo(controller)

      new ScrollMagic.Scene({
        triggerElement: '#thirdProd',
        duration: 200,
        offset: -300,
      })
        .on('enter', () => {
          thirdImageAnimationControls.start('visible')
          thirdProductControl.start('visible')
        })
        .addTo(controller)
    }
  }, [
    isMobile,
    firstImageAnimationControls,
    firstProductControl,
    secondImageAnimationControls,
    secondProductControl,
    thirdImageAnimationControls,
    thirdProductControl,
  ])

  useEffect(() => {
    const { hash } = props.history.location

    if (hash) {
      const timer = setTimeout(() => {
        const test = hash.split('#')[1]
        const element = document.getElementById(test)
        element.scrollIntoView({ behavior: 'smooth' })
      }, 1000)

      return () => clearTimeout(timer)
    }
    // eslint-disable-next-line
  }, [])

  const handleClick = () => {
    analytics.logEvent('login-home-dialog-opened')
    dispatch(setAuthDialog(true))
  }

  return (
    <>
      <Container maxWidth="lg" className="custom-spacing">
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1.3 }}
          className={s['home-tagline']}
        >
          <div>
            <p
              style={
                isMobile
                  ? { textAlign: 'center' }
                  : { textAlign: 'right', marginBottom: '2px' }
              }
            >
              {translate('home-public.title-carousel')}
            </p>
            <img
              style={isMobile ? { width: '1rem' } : {}}
              src={
                isMobile ? '/images/arrowmobile.svg' : '/images/arrow_line.svg'
              }
              alt="B-Assist"
            />
          </div>
        </motion.div>
      </Container>

      <div id="firstProd" className={s['home-products-grid']}>
        <motion.img
          src={`/images/screen1${activeLanguage === 'en' ? '-en' : ''}.png`}
          animate={firstImageAnimationControls}
          initial={`${isMobile ? 'visible' : 'hidden'}`}
          variants={{
            visible: { opacity: 1, x: '0%', transition: { duration: 1 } },
            hidden: { opacity: 0, x: '-100%', transition: { duration: 1 } },
          }}
        />
        <motion.div
          animate={firstProductControl}
          initial={`${isMobile ? 'visible' : 'hidden'}`}
          variants={{
            visible: { opacity: 1, y: 0, transition: { duration: 1 } },
            hidden: { opacity: 0, y: 50, transition: { duration: 1 } },
          }}
          className={s['product-info']}
        >
          <h1>{translate('home-public.slide-1.title')}</h1>
          <p>
            <span
              onClick={handleClick}
              style={{ color: '#D42F42', cursor: 'pointer' }}
            >
              {translate('home-public.label-login-register')}
            </span>
            {translate('home-public.slide-1.description')}
          </p>
        </motion.div>
      </div>
      <div id="secondProd" className={s['home-products-grid']}>
        <motion.div
          animate={secondProductControl}
          initial={`${isMobile ? 'visible' : 'hidden'}`}
          variants={{
            visible: { opacity: 1, y: 0, transition: { duration: 1 } },
            hidden: { opacity: 0, y: 50, transition: { duration: 1 } },
          }}
          className={s['product-info'] + ' ' + s['second-prod']}
        >
          <h1 style={{ marginLeft: 'auto' }}>
            {translate('home-public.slide-2.title')}
          </h1>

          <div>
            <article>
              <h2 style={{ fontSize: '1.5em', color: '#D42F42' }}>
                {translate('home-public.slide-2.installments.title')}
              </h2>
              <p style={{ marginLeft: 'auto' }}>
                <span
                  onClick={() =>
                    props.history.push('/installments/regular-payment')
                  }
                  style={{ color: '#D42F42', cursor: 'pointer' }}
                >
                  {translate('home-public.slide-2.installments.link')}
                </span>
                {translate('home-public.slide-2.installments.description')}
              </p>
            </article>
            <article>
              <h2 style={{ fontSize: '1.5em', color: '#D42F42' }}>
                {translate('home-public.slide-2.insurance-notification.title')}
              </h2>
              <p style={{ marginLeft: 'auto' }}>
                <span
                  onClick={handleClick}
                  style={{ color: '#D42F42', cursor: 'pointer' }}
                >
                  {translate('home-public.slide-2.insurance-notification.link')}
                </span>
                {translate(
                  'home-public.slide-2.insurance-notification.description'
                )}
              </p>
            </article>
          </div>
        </motion.div>
        <motion.img
          src={`/images/screen2${activeLanguage === 'en' ? '-en' : ''}.png`}
          animate={secondImageAnimationControls}
          initial={`${isMobile ? 'visible' : 'hidden'}`}
          variants={{
            visible: { opacity: 1, x: '0%', transition: { duration: 1 } },
            hidden: { opacity: 0, x: '100%', transition: { duration: 1 } },
          }}
        />
      </div>
      <div id="thirdProd" className={s['home-products-grid']}>
        <motion.img
          src={`/images/screen3${activeLanguage === 'en' ? '-en' : ''}.png`}
          animate={thirdImageAnimationControls}
          initial={`${isMobile ? 'visible' : 'hidden'}`}
          variants={{
            visible: { opacity: 1, x: '0%', transition: { duration: 1 } },
            hidden: { opacity: 0, x: '-100%', transition: { duration: 1 } },
          }}
        />

        <motion.div
          animate={thirdProductControl}
          initial={`${isMobile ? 'visible' : 'hidden'}`}
          variants={{
            visible: { opacity: 1, y: 0, transition: { duration: 1 } },
            hidden: { opacity: 0, y: 50, transition: { duration: 1 } },
          }}
          className={s['product-info']}
        >
          <h1>{translate('home-public.slide-3.title')}</h1>
          <p>{translate('home-public.slide-3.description')}</p>
          <ul>
            <li>
              <Link
                to="/buy-travel-insurance"
                onClick={() =>
                  analytics.logEvent('buy-travel-insurance-home-links')
                }
                className={s.link}
              >
                {translate('home-public.slide-3.link-insurance-travel')}
              </Link>
            </li>
            <li>
              <Link
                to="/buy-accident-insurance"
                onClick={() =>
                  analytics.logEvent('buy-accident-insurance-home-links')
                }
                className={s.link}
              >
                {translate('home-public.slide-3.link-insurance-accident')}
              </Link>
            </li>
            <li>
              <Link
                to="/buy-multitrip-insurance"
                onClick={() =>
                  analytics.logEvent('buy-multitrip-insurance-home-links')
                }
                className={s.link}
              >
                {translate('home-public.slide-3.link-insurance-multitrip')}
              </Link>
            </li>
            <li>
              <Link
                to="/buy-foreigners-insurance"
                onClick={() =>
                  analytics.logEvent('buy-foreigners-insurance-home-links')
                }
                className={s.link}
              >
                {translate('home-public.slide-3.link-insurance-foreigners')}
              </Link>
            </li>
          </ul>
        </motion.div>
      </div>
    </>
  )
}
