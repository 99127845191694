// Lib
import { PushNotifications } from '@capacitor/push-notifications'
// Config
import { isMobile } from '../config'


/** Native logic to run on logout */
export default async function logout() {
  // Must run only on mobile
  if (!isMobile) return
  // Clear push notification listeners
  PushNotifications.removeAllListeners()
  // Clear system notifications
  await PushNotifications.removeAllDeliveredNotifications()
}
