import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  SwipeableDrawer,
  Button,
  IconButton,
  ListItem,
  ListItemIcon,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
// Icons
import AddIcon from '@material-ui/icons/Add'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import HelpIcon from '@material-ui/icons/Help'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import DvrIcon from '@material-ui/icons/Dvr'
import { analytics } from '../../../utils/firebase'
import { useAuth } from '../../../hooks/use-auth'
import { useRouter } from '../../../hooks/use-router'
import { useTranslation } from '../../../hooks/use-translation'
import s from './s.module.css'
import ChangeLanguage from '../../ChangeLanguage'
import { Capacitor } from '@capacitor/core'

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(3),
  },
  navDrawerItemButton: {
    width: '100%',
    textAlign: 'left',
  },
  navDrawerItemButtonLabel: {
    display: 'block',
    fontWeight: 'normal',
    textTransform: 'none',
    lineHeight: '1.5',
  },
  navDrawerItemRow: {
    width: '100%',
    display: 'grid',
    alignItems: 'center',
    marginBottom: '0.3rem',
    gridTemplateColumns: '10% 1fr 20%',
  },
  drawerPaper: {
    width: '80%',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...(Capacitor.getPlatform() !== 'web'
      ? {
          '@media screen and (max-width: 769px)': {
            paddingTop: 'env(safe-area-inset-top)',
            paddingBottom: 'env(safe-area-inset-bottom)',
          },
        }
      : null),
  },
  calculatorIcon: {
    justifySelf: 'center',
    width: '23px',
    height: '23px',
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '15px',
    borderBottom: '1px solid #ddd',
  },
  pages: {
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    marginTop: 'auto',
  },
  displayNone: {
    display: 'none',
  },
  zeroPadding: {
    padding: '0',
  },
  alignSelfEnd: {
    alignSelf: 'flex-end',
  },
  brokerButton: {
    background: 'transparent',
    boxShadow: 'none',
    color: '#333333',
    fontWeight: '400',
    padding: '4.5px 8px',
  },
}))

const AppLink = ({ to, external, children }) => {
  const classes = useStyles()

  const registerAnalyticsEvent = () => {
    if (to === '/user') {
      analytics.logEvent('profile')
    }
  }

  if (external) {
    return (
      <a
        href={to}
        className={classes.navDrawerItemRow}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    )
  } else {
    return (
      <Link
        onClick={registerAnalyticsEvent}
        to={to}
        className={classes.navDrawerItemRow}
      >
        {children}
      </Link>
    )
  }
}

const DrawerMenuItem = ({ startIcon, endIcon, label, location, external }) => {
  const classes = useStyles()

  return (
    <AppLink to={location} external={external}>
      {startIcon}
      <Button
        classes={{
          root: classes.navDrawerItemButton,
          label: classes.navDrawerItemButtonLabel,
        }}
        disableRipple={true}
      >
        {label}
      </Button>
      {endIcon && <IconButton color="primary">{endIcon}</IconButton>}
    </AppLink>
  )
}

const MenuDrawer = ({ open, setOpen }) => {
  const classes = useStyles()
  const auth = useAuth()
  const router = useRouter()
  const { translate } = useTranslation()

  const name = auth.activeUser
    ? `${auth.activeUser.firstName} ${auth.activeUser.lastName}`
    : auth.user
    ? auth.user.displayName
    : ''

  const [token, setToken] = useState('')

  const handleLogoutClick = () => {
    setOpen(false)
    auth.signout()
    router.push('/')
  }

  useEffect(() => {
    async function getToken() {
      try {
        const newToken = await auth.getIdToken()
        setToken(newToken)
      } catch (e) {
        console.log('Error in Navbar get token ', e)
      }
    }

    if (auth.user && auth.claims.role === 'broker' && !token) {
      getToken()
    }
    // eslint-disable-next-line
  }, [auth])

  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

  return (
    <SwipeableDrawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      anchor="left"
      open={open}
      onOpen={() => {}}
      onClose={() => setOpen(false)}
      classes={{ paper: classes.drawerPaper }}
      disableSwipeToOpen={true}
    >
      {auth.user && (
        <>
          <div className={classes.header}>
            {name ? (
              <span>
                {translate('c-navbar.menu-drawer.hello')}, {name.split(' ')[0]}!
              </span>
            ) : (
              <span>{translate('c-navbar.menu-drawer.hello')}!</span>
            )}

            <Button onClick={handleLogoutClick} color="primary">
              {translate('c-navbar.menu-drawer.exit')}
            </Button>
          </div>

          <div className={classes.pages}>
            {auth.claims.role === 'client' && auth.claims.zg === 'true' && (
              <>
                <DrawerMenuItem
                  startIcon={
                    <img
                      width="25"
                      height="25"
                      src="/images/make-appointment.svg"
                      alt="make-appointment"
                    />
                  }
                  endIcon={<AddIcon />}
                  label={translate('c-navbar.menu-drawer.appointments')}
                  location="/make-appointment"
                />
                <DrawerMenuItem
                  startIcon={
                    <img
                      width="25"
                      height="25"
                      src="/images/examinations.svg"
                      alt="examinations"
                    />
                  }
                  endIcon={<AddIcon />}
                  label={translate('c-navbar.menu-drawer.examinations')}
                  location="/make-specialized-examination"
                />
                <DrawerMenuItem
                  startIcon={
                    <img
                      width="25"
                      height="25"
                      src="/images/reimbursement.svg"
                      alt="reimbursement"
                    />
                  }
                  endIcon={<AddIcon />}
                  label={translate('c-navbar.menu-drawer.reimbursements')}
                  location="/reimbursements"
                />

                {auth?.activeUserCard?.extraCare === 'yes' && (
                  <DrawerMenuItem
                    external
                    startIcon={
                      <img
                        width="25"
                        height="25"
                        src="/images/extra-care.svg"
                        alt="extra-care"
                      />
                    }
                    label={translate('extra-care.label')}
                    location={`https://diagnose.me/bg/partner/bulstradlife/?partnerId=${auth?.activeUserCard?.cardNumber}`}
                  />
                )}
              </>
            )}

            {auth.claims.role === 'broker' && (
              <>
                <DrawerMenuItem
                  startIcon={<HelpIcon color="secondary" />}
                  label={translate('c-navbar.menu-drawer.info')}
                  location="/"
                />
                <DrawerMenuItem
                  startIcon={<ImportContactsIcon color="secondary" />}
                  label="E-Learning"
                  location={process.env.REACT_APP_BULSTRAD_E_LEARN_PORTAL}
                  external
                />
                {auth.claims.brtype !== 'fin' && (
                  <ListItem
                    className={`${classes.navDrawerItemRow} ${classes.zeroPadding}`}
                  >
                    <ListItemIcon>
                      <DvrIcon color="secondary" />
                    </ListItemIcon>

                    <form
                      method="post"
                      action={process.env.REACT_APP_MEDEX_URL}
                    >
                      <h1 className={classes.displayNone}>
                        {translate('c-navbar.menu-drawer.user-login')}
                      </h1>

                      <input
                        className={classes.displayNone}
                        laceholder="token"
                        type="text"
                        id="firebase_token"
                        name="firebase_token"
                        value={token}
                        readOnly
                        required
                      />

                      <Button
                        className={classes.brokerButton}
                        variant="contained"
                        type="submit"
                      >
                        MEDEX
                      </Button>
                    </form>
                  </ListItem>
                )}

                {auth.claims.brtype !== 'ins' && (
                  <DrawerMenuItem
                    startIcon={
                      <img
                        className={classes.calculatorIcon}
                        src="images/calc.svg"
                        alt={translate('c-navbar.menu-drawer.calc-prod')}
                      />
                    }
                    label={translate('c-navbar.menu-drawer.calc-prod')}
                    location="/"
                  />
                )}
              </>
            )}

            <DrawerMenuItem
              startIcon={
                <img
                  width="25"
                  height="25"
                  src="/images/profile.svg"
                  alt="profile"
                />
              }
              label={translate('c-navbar.menu-drawer.profile-and-card')}
              location="/user"
            />

            <div
              style={{
                padding: '1em 0',
                borderTop: '1px solid #E1E1E1',
                borderBottom: '1px solid #E1E1E1',
                display: 'flex',
                flexDirection: 'column',
                marginTop: '1em',
              }}
            >
              {auth.claims.role === 'client' && auth.claims.zg !== 'true' && (
                <Link
                  to="/"
                  style={{
                    color: '#D42F42',
                    marginBottom: '10px',
                    borderBottom: '1px solid #E1E1E1',
                    paddingBottom: '1em',
                  }}
                >
                  {translate(
                    'c-client-dashboard.my-insurance.health-care-tab.label-connect-health-care-button'
                  )}
                </Link>
              )}
              <Link
                to="/other"
                style={{ color: '#D42F42', marginBottom: '1em' }}
              >
                {translate(
                  'c-client-dashboard.my-insurance.other-tab.other-page.label-button'
                )}
              </Link>
              <Link
                to="/installments/down-payment"
                style={{ color: '#D42F42' }}
              >
                {translate('payments-drawer-label')}
              </Link>
            </div>
          </div>
        </>
      )}

      <div className={s.footer}>
        <ChangeLanguage />

        <IconButton
          onClick={() => setOpen(false)}
          color="secondary"
          className={classes.alignSelfEnd}
          disableRipple={true}
        >
          <ChevronLeftIcon fontSize="large" />
        </IconButton>
      </div>
    </SwipeableDrawer>
  )
}

export default MenuDrawer
