// Lib
import { useEffect } from 'react'
// Mobile integration
import { useCapacitorEvents } from '../index'
// Hooks
import { useRouter } from '../../hooks/use-router'

/**
 * Listens to the deepLink event from Capacitor if
 * running inside the mobile wrapper.
 */
export default function UrlListener() {
  const { history } = useRouter()

  // Will receive a path to nav to (/something)
  const navUrl = useCapacitorEvents('deepLink')

  // Listen to navUrl changes
  useEffect(() => {
    if (navUrl !== null) history.push(navUrl)
  }, [navUrl, history])
  // Render nothing
  return null
}
