// Lib
import { PushNotifications } from '@capacitor/push-notifications'
import { Capacitor } from '@capacitor/core'

// Linkers
import { linkers } from '..'
// Config
import { isMobile } from '../config'

/** Native logic to run on login */
export default async function login() {
  // Must run only on mobile
  if (!isMobile) return
  // Request notifications permission
  const permissionStatus = await PushNotifications.requestPermissions()

  // Permission required
  if (
    !Capacitor.getPlatform() !== 'android' &&
    permissionStatus.receive !== 'granted'
  )
    return

  // Push notification registration listener
  PushNotifications.addListener('registration', (token) => {
    // Send to linker if present
    if (token && token.value) {
      linkers.msgToken.next(token.value)
      // Debug
      console.log('[MSG_TOKEN]', token.value)
    }
  })

  PushNotifications.addListener('registrationError', (error) => {
    console.error(error)
  })

  // Push notification main listener
  PushNotifications.addListener('pushNotificationReceived', (notification) => {
    // Send to linker
    linkers.pushNotification.next(notification)
  })

  // Push notification action listener
  PushNotifications.addListener(
    'pushNotificationActionPerformed',
    (notification) => {
      // Send to linker
      linkers.pushNotification.next(notification.notification)
    }
  )
  // Register push notifications
  PushNotifications.register()
}
